
export const storeParent = (root) => {
    const store = root.$store;

    const dispatchComposition = () => {
        const _key = "miners/";

        const getFarms = () => store.dispatch(_key + "GET_ALL_FARMS", 1);
        const getContainers = (body) => store.dispatch(_key + "GET_ALL_CONTAINERS", body);
        const getPools = () => store.dispatch(_key + "GET_POOL", 1);
        const getOwners = () => store.dispatch(_key + "GET_OWNERS");
        const getMiners = (id) => store.dispatch(_key + "GET_MINERS",id);
        const getWorkersById = (id) => store.dispatch(_key + "GET_WORKERS_BY_USER", id);
        const checkConnection = (body) => store.dispatch(_key + "CHECK_CONNECTION", body);
        const postMiner = (body) => store.dispatch(_key + "ADD_MINER", body);
        const getReports = (body) => store.dispatch(_key + "GET_REPORTS", body);
        const getStatistics = (body) => store.dispatch(_key + "GET_STATISTICS", body);

        const getContainer = (body) => store.dispatch(_key + "GET_CONTAINERS", body);

        const downloadPdf = (body) => store.dispatch(_key + "DOWNLOAD_PDF", body);
        const downloadXls = (body) => store.dispatch(_key + "EXCEL_DOWNLOAD",body);

        const updateMiner = (id,body) => store.dispatch(_key + "UPDATE_MINER",{id:id,body:body});

        const checkMultipleConnection = (body) => store.dispatch(_key +"CHECK_MULTIPLE_MAC_ADDRESS",body);

        const addMultipleMiners = (body) => store.dispatch(_key + "ADD_MULTIPLE_MINERS",body);

        return {
            getFarms,
            checkMultipleConnection,
            updateMiner,
            getMiners,
            getContainer,
            getContainers,
            downloadPdf,
            addMultipleMiners,
            downloadXls,
            getPools,
            checkConnection,
            getWorkersById,
            getReports,
            getOwners,
            getStatistics,
            postMiner
        }
    }

    const helpers = () => {
        const startLoading = (obj, key) => {
            obj[key] = true;
        }
        const stopLoading = (obj, key) => {
            obj[key] = false;
        }

        const computeMultipleChoose = (obj, index, targetSource) => {
            if (obj[index]) {
                delete obj[index];
            } else {
                obj = {
                    ...obj,
                    [index]: targetSource[index].id
                };
            }
            return obj;
        }

        return {
            loaders: {
                startLoading,
                stopLoading
            },
            computeMultipleChoose
        }
    }

    const gettersComposition = () => {
        return {
            farms: store.state.miners.allFarms,
            containers: store.state.miners.allContainers,
            pools: store.state.miners.pools,
            owners: store.state.miners.customers,
            minerInfo: store.state.miners.minerInfo,
            workers: store.state.miners.workers,
            miners: store.state.miners.miners.data,
            containersByFarm: store.state.miners.containers.data,
        }
    }

    const getter = () => {
        return {
            statisticsContent: store.getters['miners/getStatisticsContent'],
            statisticsBrief: store.getters['miners/getStatisticsBrief']
        }
    }

    return {
        dispatchComposition,
        gettersComposition,
        helpers,
        getter
    }
}